
import { Component, Vue } from "vue-property-decorator";
import DireccionPerfil from "@/components/Cuentas/DireccionPerfil.vue";

@Component({
  components: {
    DireccionPerfil
  },
  meta: {
    // sets document title
    title: "Cuenta Pública - Municipalidad de Quilpué",
    // optional; sets final title as "Index Page - My Website", useful for multiple level meta
    //titleTemplate: title => `${title} - My Website`,

    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Cuenta Pública - Municipalidad de Quilpué"
      },
      keywords: { name: "keywords", content: "Quilpué" },
      equiv: {
        "http-equiv": "Content-Type",
        content: "text/html; charset=UTF-8"
      }
    }
  }
})
export default class HomeView extends Vue {
  private mounted() {
    document.title = "Cuenta Pública - Municipalidad de Quilpué";
  }
}
