
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";

@Component
export default class CuentaPerfil extends Vue {
  private nombreWeb: any = "";
  private dataCuenta: any = {
    descripcion: "",
    estado: 0,
    fecha: "",
    fecha_inicio: "",
    fecha_termino: "",
    id: 0,
    id_lector: 0,
    nombre: "",
    nombre_web: ""
  };
  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoCuenta();
    } else {
      this.routerGo("Home");
    }
  }

  private getInfoCuenta() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("cuenta/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.dataCuenta = res.data;
        if (
          this.dataCuenta.direcciones &&
          this.dataCuenta.direcciones.length > 0
        ) {
          let base = process.env.VUE_APP_BASE_URL;
          for (let i = 0; i < this.dataCuenta.direcciones.length; i++) {
            if (
              this.dataCuenta.direcciones[i].foto &&
              this.dataCuenta.direcciones[i].foto != ""
            ) {
              this.dataCuenta.direcciones[i].foto =
                base + this.dataCuenta.direcciones[i].foto;
            } else {
              this.dataCuenta.direcciones[
                i
              ].foto = require("@/assets/logo_quilpue.png");
            }
          }
        }
        console.log(this.dataCuenta);
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
        this.routerGo("Home");
      });
  }

  private irDireccion(nombreWeb: any, id_direccion: any) {
    this.$router
      .push({
        name: "Direccion",
        params: { nombre: nombreWeb, id: id_direccion }
      })
      .catch(err => {
        console.log(err);
      });
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "Enero";
    } else if (mesInicio == 2) {
      mesInicio = "Febrero";
    } else if (mesInicio == 3) {
      mesInicio = "Marzo";
    } else if (mesInicio == 4) {
      mesInicio = "Abril";
    } else if (mesInicio == 5) {
      mesInicio = "Mayo";
    } else if (mesInicio == 6) {
      mesInicio = "Junio";
    } else if (mesInicio == 7) {
      mesInicio = "Julio";
    } else if (mesInicio == 8) {
      mesInicio = "Agosto";
    } else if (mesInicio == 9) {
      mesInicio = "Septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "Octubre";
    } else if (mesInicio == 11) {
      mesInicio = "Noviembre";
    } else {
      mesInicio = "Diciembre";
    }

    if (mesInicio != 0) {
      return mesInicio + " " + anhoInicio;
    }
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
