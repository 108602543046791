
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

@Component
export default class Anteriores extends Mixins(FormValidator) {
  private win = window;
  private cuentas: any = [
    {
      anho: "2022",
      descripcion: "Cuenta pública 2022",
      url: "https://docs.quilpue.cl/uploads/cuenta_publica/media/Documentos/2023/04/cuenta-publica-2022.pdf"
    },
    {
      anho: "2021",
      descripcion: "Cuenta pública 2021",
      url: "https://docs.quilpue.cl/uploads/cuenta_publica/media/Documentos/2022/04/CUENTA_PUBLICA_2021_DIGITAL.pdf"
    },
    {
      anho: "2020",
      descripcion: "Cuenta pública 2020",
      url: "https://docs.quilpue.cl/uploads/2021/03/cuenta_publica_2020.pdf"
    },
    {
      anho: "2019",
      descripcion: "Cuenta pública 2019",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-cuentapublicaquilpue2019ampliada.pdf"
    },
    {
      anho: "2018",
      descripcion: "Cuenta pública 2018",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-cuentapublica2018.pdf"
    },
    {
      anho: "2017",
      descripcion: "Cuenta pública 2017",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-cuentapublica2017detalle.pdf"
    },
    {
      anho: "2016",
      descripcion: "Cuenta pública 2016",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-cuentapublica2016detalle.pdf"
    },
    {
      anho: "2015",
      descripcion: "Cuenta pública 2015",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-cuenta-publica-2015-1.pdf"
    },
    {
      anho: "2014",
      descripcion: "Cuenta pública 2014",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-gestionanual2014.pdf"
    },
    {
      anho: "2013",
      descripcion: "Cuenta pública 2013",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-gestionanual2013.pdf"
    },
    {
      anho: "2012",
      descripcion: "Cuenta pública 2012",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-gestionanual2012.pdf"
    },
    {
      anho: "2011",
      descripcion: "Cuenta pública 2011",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-gestionanual2011.pdf"
    },
    {
      anho: "2010",
      descripcion: "Cuenta pública 2010",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-gestionanual2010.pdf"
    },
    {
      anho: "2009",
      descripcion: "Cuenta pública 2009",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-gestionanual2009.pdf"
    },
    {
      anho: "2008",
      descripcion: "Cuenta pública 2008",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-gestionanual2008.pdf"
    },
    {
      anho: "2007",
      descripcion: "Cuenta pública 2007",
      url:
        "https://docs.quilpue.cl/uploads/2019/11/20191111195437-gestionanual2007.pdf"
    }
  ];
  private mounted() {}

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private irCuenta(nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "CuentaPublica" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "CuentaPublica",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "Enero";
    } else if (mesInicio == 2) {
      mesInicio = "Febrero";
    } else if (mesInicio == 3) {
      mesInicio = "Marzo";
    } else if (mesInicio == 4) {
      mesInicio = "Abril";
    } else if (mesInicio == 5) {
      mesInicio = "Mayo";
    } else if (mesInicio == 6) {
      mesInicio = "Junio";
    } else if (mesInicio == 7) {
      mesInicio = "Julio";
    } else if (mesInicio == 8) {
      mesInicio = "Agosto";
    } else if (mesInicio == 9) {
      mesInicio = "Septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "Octubre";
    } else if (mesInicio == 11) {
      mesInicio = "Noviembre";
    } else {
      mesInicio = "Diciembre";
    }

    if (mesInicio != 0) {
      return mesInicio + " " + anhoInicio;
    }
  }
}
