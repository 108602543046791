
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

@Component
export default class Home extends Mixins(FormValidator) {
  private countScroll = 0;
  private cuentas: any = [];
  private mounted() {
    this.getCuentas();
  }

  private scrollCuentas() {
    setTimeout(() => {
      if (this.countScroll == 0) {
        const el = document.getElementById("cuentas");
        (el as any).scrollIntoView({ block: "center", behavior: "smooth" });
        this.countScroll = 0;
      }
    }, 500);
  }

  private getCuentas() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("cuentas")
      .then((res: any) => {
        this.cuentas = res.data;
        let base = process.env.VUE_APP_BASE_URL;
        for (let i = 0; i < this.cuentas.length; i++) {
          if (this.cuentas[i].foto && this.cuentas[i].foto != "") {
            this.cuentas[i].foto = base + this.cuentas[i].foto;
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private irCuenta(nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "CuentaPublica" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "CuentaPublica",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "Enero";
    } else if (mesInicio == 2) {
      mesInicio = "Febrero";
    } else if (mesInicio == 3) {
      mesInicio = "Marzo";
    } else if (mesInicio == 4) {
      mesInicio = "Abril";
    } else if (mesInicio == 5) {
      mesInicio = "Mayo";
    } else if (mesInicio == 6) {
      mesInicio = "Junio";
    } else if (mesInicio == 7) {
      mesInicio = "Julio";
    } else if (mesInicio == 8) {
      mesInicio = "Agosto";
    } else if (mesInicio == 9) {
      mesInicio = "Septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "Octubre";
    } else if (mesInicio == 11) {
      mesInicio = "Noviembre";
    } else {
      mesInicio = "Diciembre";
    }

    if (mesInicio != 0) {
      return mesInicio + " " + anhoInicio;
    }
  }
}
