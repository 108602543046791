
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";
import { GChart } from "vue-google-charts";

@Component({
  components: {
    GChart
  }
})
export default class DireccionPerfil extends Mixins(FormValidator) {
  private nombreWeb: any = "";
  private cuenta: any = "";
  private tab = "";
  private tabItemDireccion = "";
  private idDireccion: any = "";
  private treemap: any = "";
  private items: any = "";
  private departamentos: any = [];
  private tabsModels: any = [];
  private itemsDireccion: any = "";
  private dataDireccion: any = {
    id: "",
    nombre: ""
  };
  private data: Array<any> = [];
  private options: Array<any> = [];

  private chartOptions = {
    minColor: "#f00",
    midColor: "#ddd",
    maxColor: "#0d0",
    headerHeight: 15,
    fontColor: "black",
    showScale: true
  };
  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != "" &&
      this.$router.currentRoute.params.id &&
      this.$router.currentRoute.params.id != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.idDireccion = this.$router.currentRoute.params.id;
      this.getDepartamentos();
    } else {
      this.routerGo("Home");
    }
  }

  private getDepartamentos() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get(
        "departamentos?id=" + this.idDireccion + "&nombre_web=" + this.nombreWeb
      )
      .then((res: any) => {
        this.cuenta = res.data.cuenta;
        this.dataDireccion.id = res.data.id;
        this.dataDireccion.nombre = res.data.nombre;
        this.departamentos = res.data.departamentos;
        for (let i = 0; i < this.departamentos.length; i++) {
          if (this.departamentos[i].items.length > 0) {
            this.tabsModels.push(this.departamentos[i].items[0].nombre);
          }
        }
        this.itemsDireccion = res.data.itemsDireccion;

        if (this.itemsDireccion.length > 0) {
          this.tabItemDireccion = this.itemsDireccion[0].nombre;
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "Enero";
    } else if (mesInicio == 2) {
      mesInicio = "Febrero";
    } else if (mesInicio == 3) {
      mesInicio = "Marzo";
    } else if (mesInicio == 4) {
      mesInicio = "Abril";
    } else if (mesInicio == 5) {
      mesInicio = "Mayo";
    } else if (mesInicio == 6) {
      mesInicio = "Junio";
    } else if (mesInicio == 7) {
      mesInicio = "Julio";
    } else if (mesInicio == 8) {
      mesInicio = "Agosto";
    } else if (mesInicio == 9) {
      mesInicio = "Septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "Octubre";
    } else if (mesInicio == 11) {
      mesInicio = "Noviembre";
    } else {
      mesInicio = "Diciembre";
    }

    if (mesInicio != 0) {
      return mesInicio + " " + anhoInicio;
    }
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private getOpcionesDonut(grafico: any) {
    var xData: any[] = [];
    var yData: any[] = [];
    for (let i = 0; i < grafico.data.length; i++) {
      xData.push(grafico.data[i][1]);
      yData.push(parseInt(grafico.data[i][2]));
    }
    let options = {
      chart: {
        width: 600,
        type: "donut"
      },
      labels: xData
    };
    return options;
  }
  private getSeriesDonut(grafico: any) {
    var yData: any[] = [];
    for (let i = 0; i < grafico.data.length; i++) {
      yData.push(parseInt(grafico.data[i][2]));
    }
    return yData;
  }
  private getOpcionesBarra(grafico: any) {
    var xData: any[] = [];
    var yData: any[] = [];
    for (let i = 0; i < grafico.data.length; i++) {
      xData.push(grafico.data[i][1]);
      yData.push(parseInt(grafico.data[i][2]));
    }
    let options = {
      chart: {
        type: "bar",
        height: 500
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "35%"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: xData
      },
      yaxis: {
        title: {
          text: grafico.ejey
        }
      },
      colors: ["#34b1b4"],
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val;
          }
        }
      }
    };
    return options;
  }
  private getSeriesBarra(grafico: any) {
    var yData: any[] = [];
    for (let i = 0; i < grafico.data.length; i++) {
      yData.push(parseInt(grafico.data[i][2]));
    }

    return [
      {
        name: grafico.ejey,
        data: yData
      }
    ];
  }
  private getOpcionesPie(grafico: any) {
    var xData: any[] = [];
    var yData: any[] = [];
    for (let i = 0; i < grafico.data.length; i++) {
      xData.push(grafico.data[i][1]);
      yData.push(parseInt(grafico.data[i][2]));
    }
    let options = {
      chart: {
        width: 600,
        type: "pie"
      },
      labels: xData
    };
    return options;
  }
  private getSeriesPie(grafico: any) {
    var yData: any[] = [];
    for (let i = 0; i < grafico.data.length; i++) {
      yData.push(parseInt(grafico.data[i][2]));
    }
    return yData;
  }
  private getOpcionesLinea(grafico: any) {
    var xData: any[] = [];
    var yData: any[] = [];
    for (let i = 0; i < grafico.data.length; i++) {
      xData.push(grafico.data[i][1]);
      yData.push(parseInt(grafico.data[i][2]));
    }
    let options = {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: false
      },
      colors: ["#34b1b4"],
      stroke: {
        curve: "straight"
      },
      title: {
        text: grafico.ejey,
        align: "left"
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: xData
      }
    };
    return options;
  }
  private getSeriesLinea(grafico: any) {
    var yData: any[] = [];
    for (let i = 0; i < grafico.data.length; i++) {
      yData.push(parseInt(grafico.data[i][2]));
    }
    return [
      {
        name: grafico.ejey,
        data: yData
      }
    ];
  }
}
